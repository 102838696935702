<template>
    <main class="main">
        <div class="row" style="direction: rtl;">
            <div class="container login-form col-md-5">
                <div class="page-header">
                    <div class="container">
                        <h1>إنشاء حساب جديد</h1>
                    </div>
                    <!-- End .container -->
                </div>
                <!-- End .page-header -->

                <div class="container" dir="rtl">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="heading">
                                <h2 class="title">إنشاء حساب جديد</h2>
                                <p>من خلال إنشاء حساب في متجرنا ، ستتمكن من التنقل خلال عملية الدفع بشكل أسرع ، وتخزين عناوين شحن متعددة ، وعرض وتتبع طلباتك في حسابك والمزيد.</p>
                            </div>
                            <!-- End .heading -->

                            <form @submit.prevent="sign_up">
                                <h2 class="title mb-2">البيانات الشخصية</h2>
                                <input id="name" type="text" v-model="name" class="form-control" placeholder="الاسم" required>
                                <input id="phone" type="tel" v-model="phone" class="form-control" placeholder="الهاتف" required>

                                <h2 class="title mb-2">معلومات تسجيل الدخول</h2>
                                <input id="email" type="email" v-model="email" class="form-control" placeholder="البريد الالكتروني" required>
                                <input id="password" type="password" v-model="password" class="form-control" placeholder="كلمة المرور" required>
                                <p v-if="error" class="text-danger">{{ this.error }}</p>

                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="newsletter-signup">
                                    <label class="custom-control-label" for="newsletter-signup">اشترك في النشرة الإخبارية لدينا</label>
                                </div>
                                <!-- End .custom-checkbox -->

                                <div class="form-footer">
                                    <button type="submit" class="btn btn-primary ml-2">إنشاء حساب</button>
                                    <a href="/login" class="forget-pass"> لديك حساب مسبقاً ؟ تسجيل الدخول </a>
                                </div>
                                <!-- End .form-footer -->
                            </form>
                        </div>
                        <!-- End .col-md-6 -->
                    </div>
                    <!-- End .row -->
                </div>
            <!-- End .container -->
            </div>
            <div class="col-md-6">
                <img src="../../assets/images/sign-up/g124-7-4.png" alt="">
            </div>
        </div>
    </main>
</template>

<script>
export default {
    data(){
        return {
            name : "",
            phone: "",
            email : "",
            password : "",
            error: "",
        }
    },
    methods: {
        sign_up: function () {
            if(this.password.length < 8) {
                this.error = 'password must be more than 8 characters'
                return
            }
            let user = {
                name: this.name,
                phone: this.phone,
                email: this.email,
                newPassword: this.password,
            }
            this.$store.dispatch('sign_up', user)
                        .then(() => {
                            let user = {
                                username: this.email,
                                password: this.password
                            }
                            this.$store.dispatch('login', user)
                                        .then(() => {
                                            this.$router.push('/')
                                            this.$router.go()
                                        })
                        })
                        .catch(err => {
                            err.message = "Login name already used";
                            this.error = err.message;
                        })
        }
    }
}
</script>

<style scoped>
    .login-form{
        background-color: rgb(255, 255, 255);
        margin-bottom: 38px;
        margin-top: 32px;
        padding: 31px;
        border-radius: 15px;
        font-family: 'Cairo', sans-serif;
        text-align: center;
        border-style: inset;
    }
    .page-header {
        padding-top: 11px;
    }
    form {
        text-align: -webkit-center;
    }
    form h2 {
        text-align: center;
    }
    .custom-control-label:before, .custom-control-label:after {
        top: 0;
        right: 198px;
        width: 2rem;
        height: 2rem;
    }
    .form-footer {
        justify-content: center;
    }
    h1 {
        font-family: 'Cairo', sans-serif;
    }
</style>